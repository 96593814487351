import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";
// import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
// import { BsTwitterX } from "react-icons/bs";

// const homeLinks = [
//   { label: "Home", link: "/" },
//   { label: "Partners", link: "/" },
//   { label: "Edge Cloud", link: "/" },
//   { label: "Application Partners", link: "/" },
//   { label: "Infra Partners", link: "/" },
//   { label: "Contact Us", link: "/" },
//   { label: "Career", link: "/" },
//   { label: "Nife Platform", link: "/" },
//   { label: "Privacy Policy", link: "https://launch.nife.io/policies/privacy-policy/" },
//   { label: "Terms & Conditions", link: "https://launch.nife.io/policies/terms-and-conditions/" },
//   { label: "About Us | By Tech for Tech", link: "/" },
//   { label: "Blogs", link: "/" },
//   { label: "E-Book Library", link: "/" },
//   { label: "FAQs", link: "/" },
//   { label: "Platus", link: "/" }
// ];
// const categories = [
//   { name: "Software Release Management", link: "/" },
//   { name: "Release Management", link: "/" },
//   { name: "Microservices", link: "/" },
//   { name: "Machine Learning", link: "/" },
//   { name: "CI and CD", link: "/" },
//   { name: "Cloud Technology", link: "/" },
//   { name: "Data Security", link: "/" },
//   { name: "Financial Services", link: "/" },
//   { name: "Multi-Cloud Computing", link: "/" },
//   { name: "Content Delivery Networking", link: "/" },
//   { name: "Cloud Native Development", link: "/" },
//   { name: "Hybrid Cloud", link: "/" },
//   { name: "Technology", link: "/" },
//   { name: "Application Development", link: "/" },
//   { name: "Smart Cities", link: "/" },
//   { name: "Metaverse", link: "/" },
//   { name: "Deployment", link: "/" },
//   { name: "Hybrid Cloud Solutions", link: "/" },
//   { name: "Sports at Edge", link: "/" },
//   { name: "Drones", link: "/" },
//   { name: "Sports", link: "/sports" },
//   { name: "Network Slicing", link: "/" },
//   { name: "Future Communication Designs", link: "/" },
//   { name: "Hyperlocal", link: "/" },
//   { name: "Localization", link: "/" },
//   { name: "Location Based", link: "/" },
//   { name: "Healthcare", link: "/healthcare" },
//   { name: "Multi Access Edge Computing", link: "/" },
//   { name: "Containers", link: "/" },
//   { name: "News", link: "/" },
//   { name: "Contextual Ads", link: "/" },
//   { name: "Manufacturing", link: "/" },
//   { name: "Computer Vision", link: "/" },
//   { name: "Edge Computing", link: "/" },
//   { name: "Robotics", link: "/" },
//   { name: "AR", link: "/" },
//   { name: "Pandemic", link: "/" },
//   { name: "Videos at Edge", link: "/" },
//   { name: "Digitalization", link: "/" },
//   { name: "AI | ML", link: "" },
//   { name: "5G", link: "" },
//   { name: "Cloud & Edge Computing", link: "/" },
//   { name: "Cloud Gaming", link: "/" },
//   { name: "DevOps", link: "" }
// ];



function Footer() {
  return (
    <footer>
      <Container>
        <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap pt-5 pb-4">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={require("../images/logo/logo.png")}
              alt="Nife logo"
              style={{ height: "100px", aspectRatio: "16/9", objectFit: "cover" }}
              className="mb-3"
            />
            <p className="gray-100">
              Nife is a leading global provider of continuous deployment software and cloud management platform. Our cutting-edge solutions empower enterprises and developers to seamlessly launch and scale applications within the Nife Edge Grid. Simplify the complexities of 5G, edge computing, and cloud with our suite of APIs and tools, ensuring security, privacy, and cost efficiency.
            </p>
            <Typography color={"primary"} variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>
              Address</Typography>
            <p className="gray-100">Nife Labs Pte Ltd<br />#02-01, 68 Circular Rd, Singapore 049422</p>
            <div className="d-flex mt-2">
              <a href="https://www.linkedin.com/company/nifeio/" target="_blank" rel="noopener noreferrer" >
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" style={{color:"grey",marginRight:"10px"}} height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>
                
              </a>
              <a href="https://x.com/nifeio" target="_blank" rel="noopener noreferrer">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" style={{color:"grey",marginRight:"10px"}} height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
              <a href="https://www.facebook.com/nifeio/" target="_blank" rel="noopener noreferrer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" style={{color:"grey",marginRight:"10px"}} height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path></svg>
             
              </a>
              <a href="https://www.instagram.com/nife.io/" target="_blank" rel="noopener noreferrer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" style={{color:"grey",marginRight:"10px"}} height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
              
              </a>
              <a href="https://www.youtube.com/channel/UCBlr6Wk9hcq2WlOnWpYu9LA" target="_blank" rel="noopener noreferrer">
             
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" style={{color:"grey",marginRight:"10px"}} height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
              </a>
            </div>
          </motion.div>
          <span className="d-block d-md-none"></span>

        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="d-flex justify-content-between flex-column flex-md-row flex-wrap gray-100 pt-3"
        >
          <p>© 2024 Nife. All rights reserved</p>
          <p>

            <Link className="link-primary link-underline-opacity-0 mx-2" to={"https://launch.nife.io/policies/privacy-policy/"}>
              Privacy policy | Terms and Conditions
            </Link>
            v 0.1
          </p>
        </motion.div>
      </Container>
    </footer>
  );
}

export default Footer;
