import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import Template1 from './Template1';
import featuresData from './features.json';

const FeaturePage = ({slug}) => {
 
  const [feature, setFeature] = useState(null);

  useEffect(() => {
    const selectedFeature = featuresData.features.find(f => f.slug === slug);
 // Log the selected feature
    setFeature(selectedFeature);
  }, [slug]);
  

  if (!feature) return <p>Loading...</p>; // Loading state

  return <Template1 feature={feature} />;
};

export default FeaturePage;
